import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ImageLeftSection from '@fathomtech/trustalice-ui-components/dist/ImageWithText/HeroImageLeft/HeroImageLeft';
import ImageRightSection from '@fathomtech/trustalice-ui-components/dist/ImageWithText/HeroImageRight/HeroImageRight';
import ImageTopSection from '@fathomtech/trustalice-ui-components/dist/ImageWithText/HeroImageTop/HeroImageTop';
import BookDemo from '@fathomtech/trustalice-ui-components/dist/Screens/BookDemo/BookDemo';
import {
  Typography,
} from '@material-ui/core';
import Helmet from 'react-helmet';
import Alert from '@material-ui/lab/Alert';
import Layout from '../../layouts/Default';
import backgroundImage from '../../../static/img/TrustAlice-Background.jpg';
import backgroundImage1 from '../../../static/img/TrustAlice-Background2.jpg';
import Section from '../../components/Section';
import illustration1 from '../../../static/img/TrustAlice Illustrations--02.svg';
import illustration2 from '../../../static/img/TrustAlice Illustrations--03.svg';
import illustration3 from '../../../static/img/TrustAlice Illustrations--04.svg';
import illustration4 from '../../../static/img/TrustAlice Illustrations--05.svg';
import illustration5 from '../../../static/img/TrustAlice Illustrations--06.svg';
import TAImage from '../../../static/img/TrustAlice Icon.svg';
import SubscriberModal from '../../components/SubscriberModal/SubscriberModal';
import createSubscriber from '../../utils/api/Subscribers';

const imageText1 = {
  title: 'The Problem',
  // eslint-disable-next-line no-multi-str
  content: 'High incidents of fraud and malware is causing your customers to lose trust in your communications. \
    You know your customer, but do they know you?',
};
const imageText2 = {
  title: 'The Solution',
  // eslint-disable-next-line no-multi-str
  content: 'TrustAlice provides a verified communications solution for your customers, giving them the confidence \
  to respond to your communications. ',
};
const imageText3 = {
  title: 'Secure Registration',
  // eslint-disable-next-line no-multi-str
  content: 'Registered Companies go through a multi-layered security audit to ensure \
    that all credentials remain secure and trusted',
};
const imageText4 = {
  title: 'Easy To Integrate',
  // eslint-disable-next-line no-multi-str
  content: 'Integrate TrustAlice into your existing systems and processes with one simple API call \
  then continue to communicate with your customers on a channel they prefer',
};
const imageText5 = {
  title: 'Customer Satisfaction',
  // eslint-disable-next-line no-multi-str
  content: 'Customers will now know which communications to trust, \
  leading to increased customer satisfaction and engagement levels along with an \
  enhanced digital relationship.',
};

const useStyles = makeStyles((theme) => ({
  layout: {
    backgroundImage: `url(${backgroundImage})`,
    height: '92vh',
    width: '100vw',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  flexCenter: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  learnMoreContainer: {
    backgroundImage: `url(${backgroundImage})`,
  },
  TAImage: {
    width: 300,
    // margin: '0px',
  },
  loadingDiv: {
    justifyContent: 'center',
    alignContent: 'center',
    position: 'fixed',
    left: '50%',
    top: '50%',
    zIndex: 5000,
  },
  info: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    textAlign: 'left',
    maxWidth: '500px',
  },
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: '64px',
      lineHeight: '1',
    },
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '42px',
    lineHeight: '1.1',
  },
  subtitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '1.3',
    },
    fontWeight: 'normal',
    fontSize: '22px',
    lineHeight: '1.5',
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
  },
  section: {
    paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
  },
  sectionText: {
    textAlign: 'center',
    // marginBottom: theme.spacing(8),
    // maxWidth: 700,
    width: '100%',
    margin: '0px auto',
  },
  sectionTextTitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: '36px',
      lineHeight: '1',
    },
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '1.2',
  },
  sectionTextSubtitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
      lineHeight: '1.3',
    },
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: '22px',
    lineHeight: '1.5',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  learnMoreBox: {
    backgroundColor: '#fff',
    padding: '40px',
    width: 400,
    height: 400,
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
      height: '50vh',
      overflow: 'scroll',
    },
  },
  infoBlock: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      paddingLeft: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    textAlign: 'center',
  },
  infoBlockTitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: '44px',
      lineHeight: '56px',
      // marginTop: '3rem',
      // marginBottom: '3rem',
      maxWidth: 400,
      // paddingTop: '25vh',
    },
    flexGrow: 1,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '40px',
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  marginLeft: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
  },
  world: {
    [theme.breakpoints.up('md')]: {
      maxHeight: '480px !important',
      height: '60vh !important',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  backgroundImageStyle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'contain',
      // backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      paddingTop: '5vh',
      paddingBottom: '5vh',
      height: '80vh',
    },
    justifyContent: 'center',
    alignContent: 'center',
    height: '20vh',
  },
  mobileImage: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxHeight: '300px',
      marginBottom: '2rem',
    },
    display: 'none',
    margin: 'auto',
  },
  gridTitleButton: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '25vh',
    },
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  multilineColor: {
    color: 'white',
    borderColor: 'white !important',
  },
  phoneButton: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      display: 'flex',
      alignContent: 'center',
    },
  },
  heroButtons: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      alignContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '5vw',
    },
    display: 'flex',
    flexDirection: 'col',
  },
  inputDemo: {
    [theme.breakpoints.down('sm')]: {
      width: '60vw',
    },
    width: '30vw',
  },

  demoButton: {
    [theme.breakpoints.down('sm')]: {
      width: '30vw',
    },
    width: '15vw',
  },
  getInTouch: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    color: '#0080b7',
    [theme.breakpoints.down('sm')]: {
      marginRight: '2vw',
    },
    marginRight: '1vw',
  },
  scanMessageButton: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    color: '#0080b7',
  },
}));

export default function Hero() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  useEffect(() => {
    const goToPersonalPage = () => {
      localStorage.setItem('lastPage', '/business');
    };
    goToPersonalPage();
  }, []);

  const handleBookDemo = async () => {
    try {
      setLoading(true);
      await createSubscriber({ email, demoWanted: true });
      setLoading(false);
      setIsComplete(true);
      setEmail('');
    } catch (err) {
      console.error(err);
      setLoading(false);
      setErrorMessage(err.message);
    }
  };

  return (
    <Layout>
      <Helmet title={'Business | Trust Alice'} />
      <div className={classes.layout}>
      <Section className={classes.section}>
      <Grid
          container
          justify="center"
          alignItems="center"
        >
        <Grid item md={5} xs={12} className={`${classes.world}`}
          style={{ justifyContent: 'center', alignContent: 'center' }}
        >
        <img src={TAImage} alt={'Trust Alice Icon'} className={classes.backgroundImageStyle} />
        </Grid>
        <img src={TAImage} alt={'Trust Alice Icon'} className={classes.mobileImage} />
        <Grid item md={7} xs={12} className={classes.gridTitleButton}>
          <div style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <div className={classes.phoneButton}>
          <Typography
            style={{ justifyContent: 'center', alignContent: 'center', paddingBottom: '5vh' }}
            className={classes.title}
            variant="h1"
            component="h1"
          >
            Alice allows customers to trust your communications
          </Typography>
          </div>
          <div className={classes.heroButtons}>
          <Button
            className={classes.getInTouch}
            onClick={(e) => {
              e.preventDefault();
              if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'getStarted', {
                // string - required - The object that was interacted with (e.g.video)
                  category: 'Button',
                  // string - required - Type of interaction (e.g. 'play')
                  action: 'Click',
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label: 'Get Started',
                });
              }
              setModalOpen(true);
            }
              } color="primary" variant="contained"
            >
              Get In Touch
            </Button>
            <Button
            className={classes.scanMessageButton}
            onClick={(e) => {
              console.log('clicked scan message bus');
              e.preventDefault();
              if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'goToScanPage', {
                // string - required - The object that was interacted with (e.g.video)
                  category: 'Button',
                  // string - required - Type of interaction (e.g. 'play')
                  action: 'Click',
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label: 'Scan Message Business',
                });
              }
              navigate('/personal');
            }
              } color="primary" variant="contained"
            >
              Scan Message
            </Button>
          </div>
          </div>
        </Grid>
        </Grid>
      </Section>
      </div>
      <Section className={classes.section}>
        <div className={classes.sectionText}>
        <Typography
            className={classes.sectionTextSubtitle}
            variant="h4"
            component="h4"
            style={{ color: '#0080b7', fontWeight: '700' }}
          >
            Who it&apos;s for
          </Typography>
          <Typography
            className={classes.sectionTextTitle}
            variant="h2"
            component="h2"
          >
            Help your customers verify it&apos;s you
          </Typography>
          <Typography
            className={classes.sectionTextSubtitle}
            variant="body1"
            component="body1"
          >
            Using a mobile app for both Apple and Android, your customers get independent verification of all your
            communications providing a trusted digital relationship.
          </Typography>
        </div>
      </Section>
      <ImageLeftSection
          data={imageText1}
          image={illustration1}
        />
      <ImageRightSection
          data={imageText2}
          image={illustration2}
        />
        <div style={{ backgroundImage: `url(${backgroundImage1})` }}>
        <Section className={classes.section}>
        <div className={classes.sectionText}>
        <Typography
            className={classes.sectionTextSubtitle}
            variant="h4"
            component="h4"
            style={{ color: '#00a9e1', fontWeight: '700' }}
          >
            Solutions
          </Typography>
          <Typography
            className={classes.sectionTextTitle}
            variant="h3"
            component="h3"
            style={{ paddingBottom: '10vh', fontSize: '42px' }}
          >
            Our Products
          </Typography>
          <Grid container style={{ width: '100%' }} justify="center"
            alignItems="center" >
            <Grid md={6} sm={12} style={{
              justifyContent: 'center', display: 'flex', alignItems: 'center', paddingBottom: '10vh',
            }}>
            <Box
              className={classes.learnMoreBox}
              borderRadius="10%" fontWeight={500} fontSize={18}
            >
            <Typography
              className={classes.sectionTextSubtitle}
              variant="h2"
              component="h2"
            >
              Transactions API
            </Typography>
            <Typography
            className={classes.sectionTextTitle}
            variant="h2"
            component="h2"
          >
            Alice Notify
          </Typography>
            <Typography
              className={classes.sectionTextSubtitle}
              variant="h4"
              component="h4"
            >
              Alice provides a verified communication solution to allow your customer to respond in confidence.
            </Typography>
            <Button
                color="secondary" variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'learnMore', {
                    // string - required - The object that was interacted with (e.g.video)
                      category: 'Button',
                      // string - required - Type of interaction (e.g. 'play')
                      action: 'Click',
                      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                      label: 'Learn More',
                    });
                  }
                  setModalOpen(true);
                }}
              >
                Learn More
              </Button>
            </Box>
            </Grid>
            <Grid md={6} sm={12} style={{
              justifyContent: 'center', display: 'flex', alignItems: 'center', paddingBottom: '10vh',
            }}>
            <Box
              className={classes.learnMoreBox}
              // style={{ paddingLeft: '20px' }}
              borderRadius="10%" fontWeight={500} fontSize={18}
            >
            <Typography
              className={classes.sectionTextSubtitle}
              variant="h4"
              component="h4"
            >
              Protection API
            </Typography>
          <Typography
            className={classes.sectionTextTitle}
            variant="h2"
            component="h2"
          >
            Alice Protect
          </Typography>
          <Typography
              className={classes.sectionTextSubtitle}
              variant="h4"
              component="h4"
              style={{ paddingBottom: '20px' }}
            >
              Alice provides early warning of scams that are targeting your customers.
            </Typography>
              <Button
                color="secondary" variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'learnMore2', {
                    // string - required - The object that was interacted with (e.g.video)
                      category: 'Button',
                      // string - required - Type of interaction (e.g. 'play')
                      action: 'Click',
                      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                      label: 'Learn More 2',
                    });
                  }
                  setModalOpen(true);
                }}
              >
                Learn More
              </Button>
            </Box>
            </Grid>
          </Grid>
        </div>
        </Section>
        </div>
        <ImageLeftSection
          data={imageText3}
          image={illustration3}
        />
        <ImageRightSection
          data={imageText4}
          image={illustration4}
        />
        <ImageTopSection
          data={imageText5}
          image={illustration5}
        />
    <BookDemo
      handleBookDemo={handleBookDemo}
      setLoading={setLoading}
      setEmail={setEmail}
      loading={loading}
    />
    <SubscriberModal
      open={modalOpen}
      handleClose={() => setModalOpen(false)}
      isLoading={loading}
      setIsLoading={setLoading}
    />
    { loading
        && <div className={classes.loadingDiv}>
          <CircularProgress size={60} color="primary"/>
        </div>
      }
      {
        isComplete
        && <Alert
            onClose={() => { setIsComplete(false); }}
            severity="success"
            className={classes.alert}
        >
            Thank you, we are really excited to share Trustalice with you.
        </Alert>
      }
      {
        errorMessage
        && <Alert
            onClose={() => { setErrorMessage(''); }}
            severity="error"
            className={classes.alert}
        >
            An error has occured, please try again later.
        </Alert>
      }
    </Layout>
  );
}

Hero.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

export const guidesPageQuery = graphql`
  query GuidesQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
